<template>
  <div :style="{ marginTop: isWxApplets ? '46px' : '' }">
    <template v-if="isWxApplets">
      <van-nav-bar
        title="积分商城"
        fixed
        left-arrow
        @click-left="onClickLeft"
      />
    </template>
    <!-- <div style="height: 25px"></div> -->
    <!-- 搜索 -->
    <div class="top">
      <!-- <img
        @click="$router.back(-1)"
        src="../../../assets/ShopImg/left.png"
        alt=""
      /> -->
      <van-search
        class="seach_e"
        v-model="value"
        shape="round"
        background="#fff"
        placeholder="请输入搜索关键词"
        @search="Search"
      />
    </div>

    <!-- 详情 -->
    <div class="detail">
      <div class="detail_1">
        <img src="../../../assets/ShopImg/icon6.png" alt="" />
        <p>{{ user.Point ? user.Point : 0 }}</p>
      </div>
      <router-link class="detail_2" to="/shop/pointsmall/detail">
        <p>积分详情</p>
        <img src="../../../assets/ShopImg/right2.png" alt="" />
      </router-link>
    </div>

    <!-- 筛选 -->
    <div class="select">
      <div @click="sortClick('Sort')">
        <p :class="sortField == 'Sort' ? 'selected' : ''">综合排序</p>
        <!-- <img class="three" src="../../../assets/ShopImg/three.png" alt="" /> -->
      </div>
      <div @click="sortClick('DefaultPoints')">
        <p :class="sortField == 'DefaultPoints' ? 'selected' : ''">积分排序</p>
        <img
          class="three"
          :src="
            sortType == 'desc'
              ? require('../../../assets/ShopImg/three.png')
              : require('../../../assets/ShopImg/up.png')
          "
          alt=""
          v-if="sortField == 'DefaultPoints'"
        />
      </div>
    </div>

    <div style="height: 10px; background: #f5f5f5"></div>

    <div class="outisde">
      <div style="height: 10px"></div>
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="postQeryShopGoodsList"
      >
        <Waterfall
          ref="waterfall"
          :list="goodsList"
          :gutter="13"
          backgroundColor="#ffffff"
          :breakpoints="{ 500: { rowPerView: 2 } }"
        >
          <template slot="item" slot-scope="props">
            <div style="overflow: hidden">
              <div :style="initCardStyle(props)">
                <router-link
                  :to="'/shop/goods/detail/?id=' + props.data.Id"
                  class="for_you"
                >
                  <img
                    :src="props.data.CoverImg"
                    alt=""
                    @load="$refs.waterfall.refresh()"
                  />

                  <div class="hot_conntent_div_money for_you_money">
                    <p>
                      <span>{{ props.data.DefaultPoints }}</span>
                      积分{{ props.data.DefaultPrice > 0 ? "＋￥" : "" }}
                      <span v-if="props.data.DefaultPrice > 0">{{
                        props.data.DefaultPrice
                      }}</span>
                    </p>
                    <p>￥{{ props.data.DefaultOriginalPrice }}</p>
                  </div>
                  <div class="title decimalPoint-2">
                    {{ props.data.GoodsName }}
                  </div>

                  <div class="btns">立即兑换</div>
                </router-link>
              </div>
            </div>
          </template>
        </Waterfall>
      </van-list>
      <div v-if="goodsList.length <= 0" style="background-color: #fff">
        <nocontent name="暂无数据"></nocontent>
      </div>
    </div>
  </div>
</template>

<script>
import { qeryShopGoodsList } from "@/api/shop";
import Waterfall from "vue-waterfall-plugin";
import nocontent from "@/views/shop/components/nocontent";
export default {
  components: {
    Waterfall,
    nocontent,
  },
  data() {
    return {
      value: "",
      goodsList: [],
      isLoad: false,
      isEnd: false,
      pageIndex: 1,
      pageSize: 10,
      loading: false,
      finished: false,
      categoryId: "",
      minPrice: "", //最低价
      maxPrice: "", //最高价
      minPoints: "",
      maxPoints: "",
      sortType: "asc",
      sortField: "Sort",
      user: {},
      isSetInitStyle: true,
      isWxApplets: true,
    };
  },
  created() {
    //判断是否是微信浏览器的函数
    var ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      document.title = "积分商城";
      this.isWxApplets = false;
    }
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#ffffff");
  },
  mounted() {
    if (localStorage.getItem("userInfo") != null) {
      this.user = JSON.parse(localStorage.getItem("userInfo"));
    } else {
      this.user.Id = 0;
    }

    // this.postQeryShopGoodsList();
    // window.addEventListener('scroll', this.listenScroll);
  },
  methods: {
    // 顶部返回
    onClickLeft() {
      window.history.go(-1);
    },
    // 初始化卡片样式
    initCardStyle(props) {
      if (this.isSetInitStyle) {
        return {
          width: `${props.data.itemWidth}px`,
          height: `${
            ((props.data.itemWidth - 20) / props.data.width) * props.data.height
          }px`,
          // backgroundColor: props.data.blankColor
        };
      }
    },
    Search() {
      this.shows = false;
      this.pageIndex = 1;
      this.goodsList = [];
      this.isEnd = false;
      this.postQeryShopGoodsList();
    },
    SelOne(i) {
      this.cur = i;
    },
    sortClick(sortField) {
      if (this.sortField != sortField) {
        this.sortType = "asc";
      } else {
        if (this.sortType == "asc") {
          this.sortType = "desc";
        } else if (this.sortType == "desc") {
          this.sortType = "asc";
        }
      }
      this.sortField = sortField;
      if (this.sortField == "Sort") {
        this.sortType = "asc";
      }

      this.pageIndex = 1;
      this.goodsList = [];
      this.isEnd = false;
      this.postQeryShopGoodsList();
    },
    // 列表数据
    async postQeryShopGoodsList() {
      let parm = {};
      parm.pageIndex = this.pageIndex;
      parm.pageSize = this.pageSize;
      parm.CategoryId = this.categoryId;
      parm.IsShelf = 1;
      parm.minPrice = this.minPrice;
      parm.maxPrice = this.maxPrice;
      parm.maxPoints = this.maxPoints;
      parm.minPoints = this.minPoints;
      parm.keyWords = this.value;
      parm.sortType = this.sortType;
      parm.sortField = this.sortField;
      parm.PayMehtod = 0;
      const res = await qeryShopGoodsList(parm);
      this.goodsList = this.goodsList.concat(res.data);
      console.log(this.goodsList);
      this.pageIndex++;
      // 加载状态结束
      this.loading = false;
      // 数据全部加载完成
      if (this.goodsList.length >= res.dataCount) {
        this.finished = true;
      } else {
        this.finished = false;
      }
      this.$nextTick(() => {
        this.$refs.waterfall.refresh();
      });
      // this.isLoad = false;
      // if (res.data.length == 0) {
      //   this.isEnd = true;
      // }
    },
    //滚动
    listenScroll() {
      let that = this;
      let ele = document.documentElement;
      let scr = ele.scrollTop; // 向上滚动的那一部分高度
      let clientHeight = ele.clientHeight; // 屏幕高度也就是当前设备静态下你所看到的视觉高度
      let scrHeight = ele.scrollHeight; // 整个网页的实际高度，兼容Pc端
      if (scr + clientHeight + 5 >= scrHeight) {
        // console.log(that.isEnd);
        // if (that.isLoad == false||this.isEnd == false) {
        if (that.isEnd == false) {
          // that.isLoad = true;
          that.pageIndex++;
          that.postQeryShopGoodsList();
        }
      }
    },
  },
  beforeDestroy() {
    // window.removeEventListener('scroll', this.listenScroll);
  },
};
</script>

<style lang="scss" scoped>
.top {
  display: flex;
  align-items: center;
  padding: 0 3% 0 4%;

  img {
    width: 18px;
    height: 18px;
  }

  .seach_e {
    width: 100%;

    .van-search__content {
      border: 1px solid #000000;
    }
  }
}

.detail {
  padding: 0 4%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;

  .detail_1 {
    display: flex;
    align-items: center;

    img {
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }

    p {
      font-size: 15px;
      color: #f49d22;
      font-weight: 700;
    }
  }

  .detail_2 {
    display: flex;
    align-items: center;

    img {
      width: 19px;
      height: 14px;
    }

    p {
      font-size: 14px;
      color: #bebebe;
      font-weight: 700;
    }
  }
}

.select {
  width: 100%;
  margin: 0 auto;
  height: 50px;
  line-height: 50px;
  font-size: 13px;
  border-top: 2px solid #f5f5f5;
  display: flex;
  z-index: 200;
  background: #fff;
  justify-content: space-around;
  align-items: center;

  div {
    display: flex;
    align-items: center;

    p {
      color: #555555;
      font-size: 13px;
    }

    .three {
      width: 8px;
      height: 6px;
      margin-left: 5px;
    }

    .sx {
      width: 16px;
      height: 20px;
      margin-left: 3px;
      margin-top: 3px;
    }
  }

  .selected {
    color: #000000;
  }
}

.outisde {
  // width: 92%;
  margin: 0 auto;
}

.for_you {
  width: 50%;
  margin-bottom: 15px;
  overflow: hidden;
  padding-bottom: 20px;

  img {
    width: 100%;
    margin: 0 auto;
    border-radius: 6px;
  }

  .for_you_money {
    min-height: 30px;
    line-height: 30px;
  }
}

.hot_conntent_div_money {
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    font-size: 0.625rem;
    span {
      font-weight: bold;
      font-size: 15px;
    }
    &:first-child {
      color: #fe4a4a;
    }
    &:last-child {
      color: #c9c9c9;
    }
  }
}
// .hot_conntent_div_money span:nth-child(1) {
//   color: #fe4a4a;
// }

// .hot_conntent_div_money span:nth-child(2) {
//   color: #fe4a4a;
//   font-size: 8px;
// }

// .hot_conntent_div_money span:nth-child(3) {
//   color: #fe4a4a;
//   // text-decoration: line-through;
//   font-weight: bold;
//   font-size: 10px;
// }

// .hot_conntent_div_money span:nth-child(4) {
//   color: #fe4a4a;
//   // text-decoration: line-through;
//   font-weight: bold;
//   font-size: 10px;
// }

// .hot_conntent_div_money span:nth-child(5) {
//   color: #fe4a4a;
//   // text-decoration: line-through;
//   font-weight: bold;
//   font-size: 15px;
// }

// .hot_conntent_div_money span:nth-child(6) {
//   color: #c9c9c9;
//   text-decoration: line-through;
//   font-weight: bold;
//   font-size: 8px;
//   margin-left: 10px;
// }

.hot_conntent_div_sub {
  width: 120px;
  //   height: 44px;
  font-size: 14px;
  margin-top: 10px;
  font-weight: bold;
  color: #333333;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.btns {
  width: 80px;
  height: 22px;
  line-height: 22px;
  color: #fff;
  background: linear-gradient(99deg, #000000 0%, #000000 100%);
  border-radius: 30px;
  font-size: 11px;
  text-align: center;
  margin: 10px auto;
}

/deep/.waterfull-grid {
  background: none !important;
  .waterfull-item-box {
    background-color: #fff;
    // margin-bottom: 10px;
    border-radius: 10px;
    // padding-bottom: 10px;
    .hot_conntent_money {
      display: block;
      float: none;
      margin: 0;
      width: clac(100% - 20);
      padding: 0 10px;
    }
    .title {
      font-size: 14px;
      font-weight: bold;
      color: #333333;
    }
  }
}
/deep/ .van-nav-bar .van-icon {
  color: #000;
  font-size: 20px;
}
</style>
